body {
  font-family: 'Open Sans', sans-serif !important;
  background-color: #F7FC0F;
  -moz-transition: all 3s ease-in;
  -webkit-transition: all 3s ease-in;
  -o-transition: all 3s ease-in;
  transition: all 3s ease-in;
}

img {
  width: 100%;
}


#wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  min-height: 800px;
}

.grid {
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 900px;
  margin: auto;
  flex-wrap: wrap;

  .col {
    width: 48%;
    position: relative;

    @media screen and (max-width: $desktopsize) {}

    @media screen and (max-width: $laptopsize) {}

    @media screen and (max-width: $tabletsize) {
      width: 100%;
      min-height: 200px;

    }


    @media screen and (max-width: $mobilesize) {}

    .col-content {
      padding-top: 100px;
      padding-bottom: 100px;

      @media screen and (max-width: $tabletsize) {
        padding-top: 0;
        padding-bottom: 0;
        text-align: center;

        h3 {
          text-align: center;
        }
      }
    }
  }

}


.phone {
  width: 56%;
  height: 100%;

  // background-color:rgba(0,0,0,.4);
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  -webkit-transition: all .8s ease-in-out;
  -moz-transition: all .8s ease-in-out;
  -ms-transition: all .8s ease-in-out;
  -o-transition: all .8s ease-in-out;
  transition: all .8s ease-in-out;

  @media screen and (max-width: $tabletsize) {
    width: 100px;
    right: calc(50% - 50px);
  }

}

.phone-1 {
  z-index: 3;
  background-image: url(../../img/screen-1.png);
}

.phone-2 {
  z-index: 2;
  background-image: url(../../img/screen-2.png);
}

.phone-2.animate {
  right: 20%;
  top: -2%;

  @media screen and (max-width: $tabletsize) {
    top: 0;
    right: calc(50% - 100px);
  }
}

.phone-3 {
  z-index: 1;
  background-image: url(../../img/screen-3.png);
}

.phone-3.animate {
  right: 38%;
  top: -4%;

  @media screen and (max-width: $tabletsize) {
    top: 0;
    right: calc(50%);
  }
}

.batches {
  max-width: 400px;
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  

  @media screen and (max-width: $mobilesize) {}

  // justify-content: space-between;
  .col {
    width: 49%;
    height: 60px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: $mobilesize) {
      width: 100%;

    }


  }
}

.appstore {
  background-image: url('../../img/apple-batch.svg');

}

.playstore {
  background-image: url('../../img/play-batch.png');

}

#content-inner{
  width: 80%;
  max-width: 900px;
  margin:auto;
}



.color-red {
  background-color: #FC0F0F;  
}

.color-yellow {
  background-color: #F7FC0F;
}

.color-green {
  background-color: #24C827;
}