@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');



body {
    color: #333;
} 
  
h1 {
    text-align: center;
    font-size: 14pt !important; 
    width: 100%;
    font-weight: 200;
    font-weight:800;
}

h2 {
    text-align: left;
    font-size: 14pt;
    width: 100%;
    margin-bottom: 20px;
}

h3 {
    text-align: left;
    text-transform: uppercase;
    font-size: 28pt;
    line-height: 33pt;
    @media screen and (max-width: $tabletsize) {
        font-size:18pt;  
        line-height: 20pt; 
    }

}

p{
    font-size:14pt; 
    @media screen and (max-width: $tabletsize) {
        font-size:11pt;  
    }
}