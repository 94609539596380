footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .meta {
    width: 100%;
    display: flex;
    justify-content: center;

    a,
    p {
      color: black;
      font-size: 9pt;
      text-transform: uppercase;
      margin-top: 15px;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    align-self: center;

    li {
      list-style: none;
      display: inline;
      a {
        &:hover {
          text-decoration: underline;
        }
      }

      &:first-child {
        &:after {
          content: "-";
        }
      }
    }
  }

  .menu-footer-menu-container {
    display: flex;
  }
}

.shoutcast {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .poweredby {
    width: 100%;
    text-align: center;
    color: black;
    font-size: 7pt;
    text-transform: uppercase;
    margin-top: 15px;
    font-weight: bold;
  }
  .shoutcast-logo {
    width: 130px;
    height: 50px;
    background-position: center;
    background-size: contain;
    background-image: url(../../img/shoutcast-logo-2.png);
    background-repeat: no-repeat;
    display: block;
    margin: auto;
  }
}
